import { initApp } from '../../packages/ecomm-vue/dist/thirstie-ecomm-vue.js';
import appConfig from './.env.json';

const apiKey = appConfig.APIKEY;
const mapsKey = appConfig.MAPSKEY;

const thirstieAppConfig = {
  APIKEY: apiKey,
  MAPSKEY: mapsKey,
  primaryColor: '#154D5B',
  secondaryColor: '#ffffff',
  brandLogo: 'https://media.thirstie.cloud/content/2Dt1hdN5CDkHNn7UBCQ5yH,x250f.png',
  routes: {
    checkout: '/checkout.html'
  },
  supportEmail: 'technology@thirstie.com',
  experimental: true
};

globalThis.__VUE_PROD_DEVTOOLS__ = process.env.NODE_ENV === 'development';
globalThis.__VUE_PROD_HYDRATION_MISMATCH_DETAILS__ = process.env.NODE_ENV === 'development';
globalThis.__VUE_OPTIONS_API__ = false;
initApp(thirstieAppConfig);
